._84v7yq_spinner {
  z-index: 2;
  width: 3rem;
  height: 3rem;
  background: url("logo.616677dc.png") 0 0 / contain no-repeat;
  margin: -1.5rem 0 0 -1.5rem;
  animation: 1s linear infinite _84v7yq_rotate;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes _84v7yq_rotate {
  100% {
    transform: rotate(360deg);
  }
}

:root {
  --bs-body-color: #58627a;
  --bs-body-bg: #eff1f6 !important;
  --bs-body-color-rgb: 88, 98, 122 !important;
  --bs-primary-rgb: 45, 67, 255 !important;
  --bs-secondary-rgb: 88, 98, 122 !important;
  --bs-info-rgb: 140, 158, 204 !important;
  --bs-warning-rgb: 255, 143, 45 !important;
  --bs-success-rgb: 14, 164, 11 !important;
}

.btn-secondary {
  background-color: rgb(var(--bs-secondary-rgb)) !important;
  border-color: rgb(var(--bs-secondary-rgb)) !important;
}

.btn-outline-primary {
  color: rgb(var(--bs-primary-rgb)) !important;
  border-color: rgb(var(--bs-primary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-primary-rgb), .1) !important;
}

.btn-outline-secondary {
  color: rgb(var(--bs-secondary-rgb)) !important;
  border-color: rgb(var(--bs-secondary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-secondary-rgb), .1) !important;
}

.btn-outline-warning {
  color: rgb(var(--bs-warning-rgb)) !important;
  border-color: rgb(var(--bs-warning-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-warning-rgb), .1) !important;
}

.form-label {
  font-weight: 500;
}

.form-text {
  display: block;
  margin-top: .5rem !important;
}

.form-control {
  color: var(--bs-info-rgb) !important;
}

.modal-content {
  box-shadow: .5rem .75rem 1rem #bcc2d980, -.5rem -.75rem 1rem #ffffffb3;
  box-shadow: none;
  background-color: #fff;
  background: #eff1f6 !important;
  border-radius: 2.5rem !important;
}

.modal-content .form-control:focus {
  box-shadow: none;
  background: #2d43ff14;
  border: 1px solid #2d43ff;
}

.modal-content, .modal-content td {
  padding: 1.5rem !important;
}

.modal-content tr:first-child > td:first-child {
  border-top-left-radius: 2.5rem;
}

.modal-content tr:first-child > td:last-child {
  border-top-right-radius: 2.5rem;
}

.modal-content tr:last-child > td:first-child {
  border-bottom-left-radius: 2.5rem;
}

.modal-content tr:last-child > td:last-child {
  border-bottom-right-radius: 2.5rem;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.inner-shadow {
  box-shadow: inset -.5rem -.75rem 1rem #ffffffb3, inset .5rem .75rem 1rem #bcc2d980;
}

.outer-shadow {
  background-color: #fff;
  box-shadow: .5rem .75rem 1rem #bcc2d980, -.5rem -.75rem 1rem #ffffffb3;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
  font-size: inherit;
  color: inherit;
  background: none;
  border: none;
}

:not(:empty)::-webkit-scrollbar {
  width: 2px;
  background: none;
}

:not(:empty)::-webkit-scrollbar-thumb {
  background: #3b4966;
  border-radius: 1px;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.round-card {
  background-color: #fff;
  box-shadow: .5rem .75rem 1rem #bcc2d980, -.5rem -.75rem 1rem #ffffffb3;
  border-radius: 2.5rem !important;
}

.round-card .form-control:focus {
  box-shadow: none;
  background: #2d43ff14;
  border: 1px solid #2d43ff;
}

.round-card, .round-card td {
  padding: 1.5rem !important;
}

.round-card tr:first-child > td:first-child {
  border-top-left-radius: 2.5rem;
}

.round-card tr:first-child > td:last-child {
  border-top-right-radius: 2.5rem;
}

.round-card tr:last-child > td:first-child {
  border-bottom-left-radius: 2.5rem;
}

.round-card tr:last-child > td:last-child {
  border-bottom-right-radius: 2.5rem;
}

.modal-dialog .modal-header {
  border: none;
}

.modal-dialog .modal-body {
  padding-top: 0;
}

.table1 thead th {
  border: none !important;
}

.table1 tbody {
  background-color: #fff;
  box-shadow: .5rem .75rem 1rem #bcc2d980, -.5rem -.75rem 1rem #ffffffb3;
  border: none !important;
  border-radius: 2.5rem !important;
}

.table1 tbody .form-control:focus {
  box-shadow: none;
  background: #2d43ff14;
  border: 1px solid #2d43ff;
}

.table1 tbody, .table1 tbody td {
  padding: 1.5rem !important;
}

.table1 tbody tr:first-child > td:first-child {
  border-top-left-radius: 2.5rem;
}

.table1 tbody tr:first-child > td:last-child {
  border-top-right-radius: 2.5rem;
}

.table1 tbody tr:last-child > td:first-child {
  border-bottom-left-radius: 2.5rem;
}

.table1 tbody tr:last-child > td:last-child {
  border-bottom-right-radius: 2.5rem;
}

.table1 tbody td {
  text-align: left;
  border: none !important;
}

.accordion {
  --bs-accordion-btn-padding-x: 0rem !important;
}

.accordion-button {
  font-weight: 500;
  color: #0d1220 !important;
  --bs-accordion-active-color: #fff !important;
  --bs-accordion-active-bg: #fff !important;
  --bs-accordion-btn-focus-border-color: #fff !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
}

.accordion-button:not(.collapsed):after, .accordion-button:after {
  background-image: url("arrow-down.4ab80d75.svg") !important;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #2d43ff !important;
  --bs-btn-border-color: #2d43ff !important;
}

.navbar-collapse .nav-link.active {
  color: #2d43ff !important;
  border-bottom: 2px solid #2d43ff !important;
}

@media (max-width: 576px) {
  .navbar-collapse .nav-link.active {
    border-bottom: none !important;
  }
}

.offcanvas.offcanvas-end {
  width: 60% !important;
}

.nav-pills {
  font-weight: 500 !important;
}

.nav-pills .nav-link {
  color: #000 !important;
}

.nav-pills .nav-link.active {
  color: #fff !important;
  background-color: rgb(var(--bs-primary-rgb)) !important;
}

.navbar-text .dropdown-item:hover {
  color: rgb(var(--bs-primary-rgb)) !important;
  background-color: #2d43ff0d !important;
}

input::-webkit-outer-spin-button, input[type="search"]::-webkit-search-cancel-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.btn-orange, .btn-transfer, .btn-custom-blue {
  border-color: #0000 !important;
  padding: 6px 10px !important;
}

.btn-orange {
  color: #ff8f2d !important;
}

.btn-transfer {
  color: #7a2dff !important;
}

.custom-list-wrap {
  margin-bottom: 4rem !important;
  padding-bottom: 4rem !important;
}

._02vYTG_footer ._02vYTG_icon-button {
  margin: 0 1rem;
  position: relative;
}

._02vYTG_footer ._02vYTG_icon-button:last-child:before {
  content: "";
  width: 2px;
  height: 1.5rem;
  background: #fff;
  display: inline-block;
  position: absolute;
  left: -1rem;
}

@media (min-width: 768px) and (min-width: 1550px) {
  ._02vYTG_main {
    max-width: 65vw !important;
  }
}

@media (min-width: 1200px) and (max-width: 1550px) {
  ._02vYTG_main {
    max-width: 75vw !important;
  }
}

@font-face {
  font-family: iconfont;
  src: url("//at.alicdn.com/t/font_3378940_0ccboax4ljac.woff2?t=1651775743006") format("woff2"), url("//at.alicdn.com/t/font_3378940_0ccboax4ljac.woff?t=1651775743006") format("woff"), url("//at.alicdn.com/t/font_3378940_0ccboax4ljac.ttf?t=1651775743006") format("truetype");
}

._2TdRSq_iconfont {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-style: normal;
  font-family: iconfont !important;
}

._2TdRSq_icon-arrow-down:before {
  content: "";
}

._2TdRSq_icon-discord:before {
  content: "";
}

._2TdRSq_icon-github:before {
  content: "";
}

._2TdRSq_icon-medium:before {
  content: "";
}

._2TdRSq_icon-twitter:before {
  content: "";
}

._2TdRSq_icon-minus:before {
  content: "";
}

._2TdRSq_icon-copy:before {
  content: "";
}

._2TdRSq_icon-plus:before {
  content: "";
}

@font-face {
  font-family: ADAM\.CG PRO;
  font-style: normal;
  font-weight: 400;
  src: url("ADAM.CGPRO.4a824602.woff") format("woff");
}

@font-face {
  font-family: Eras Demi ITC;
  font-style: normal;
  src: url("ITCErasStd-Demi.2916fcd3.woff") format("woff");
}

._2TdRSq_mystery-box-page {
  color: #6876a1;
  width: 100%;
  background: #050a21;
  overflow: hidden;
}

._2TdRSq_mystery-box-page ._2TdRSq_mystery-desc-title {
  color: #d0d5e6;
  margin-bottom: 1rem;
}

._2TdRSq_reward-table {
  width: 100%;
}

._2TdRSq_reward-table th, ._2TdRSq_reward-table td {
  border: 1px solid #3d456b;
  padding: .5rem .4rem;
}

._2TdRSq_reward-table th:first-child, ._2TdRSq_reward-table td:first-child {
  width: 42%;
  padding-right: .2rem;
}

._2TdRSq_reward-table th:last-child, ._2TdRSq_reward-table td:last-child {
  width: 50%;
}

@media (min-width: 576px) {
  ._2TdRSq_reward-table th:first-child, ._2TdRSq_reward-table td:first-child {
    width: 25%;
  }
}

._2TdRSq_mystery-box-wrap {
  max-width: 800px;
  padding: 30px 15px;
}

._2TdRSq_mystery-box-card {
  border: 1px solid;
  border-image: linear-gradient(#474966, #2a2c49) 1;
  margin-bottom: 30px;
  position: relative;
}

._2TdRSq_mystery-box-card:before {
  content: "";
  width: 0;
  height: 0;
  border: 30px solid #0000;
  border-left-color: #050a21;
  display: block;
  position: absolute;
  top: -31px;
  left: -1px;
}

._2TdRSq_mystery-box-card:after {
  content: "";
  width: 0;
  height: 0;
  border: 30px solid #0000;
  border-right-color: #050a21;
  display: block;
  position: absolute;
  bottom: -30px;
  right: -2px;
}

._2TdRSq_mystery-box-card-inner {
  height: 100%;
  overflow: hidden;
}

._2TdRSq_mystery-box-card-inner:after {
  content: "";
  width: 0;
  height: 0;
  border: 30px solid #0000;
  border-right-color: #2a2c49;
  display: block;
  position: absolute;
  bottom: -30px;
  right: 0;
}

._2TdRSq_mystery-box-card-header {
  height: 30px;
  text-indent: 50px;
  color: #fff;
  text-shadow: 0 0 4px #0f39ff;
  background: url("card_bg_top.d2503b2b.png") 0 0 / auto 100% no-repeat;
  margin-top: -1px;
  margin-left: -1px;
  font-family: ADAM\.CG PRO, sans-serif;
  font-size: 1rem;
  font-weight: 100;
  line-height: 31px;
}

._2TdRSq_mystery-box-card-body {
  padding: 1.1rem;
}

._2TdRSq_mystery-box-card-body-hd {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

._2TdRSq_mbcbh-left {
  color: #fff;
  text-shadow: 0 0 4px #0f39ff;
  font-family: ADAM\.CG PRO, sans-serif;
}

._2TdRSq_mbcbh-left:before {
  content: "";
  width: 2px;
  height: 12px;
  background: #fffe30;
  margin-right: 10px;
  display: inline-block;
  box-shadow: 0 0 1px #0f39ff;
}

._2TdRSq_mystery-box-card-body-desc {
  margin-bottom: .5rem;
  font-size: 12px;
  line-height: 1.2rem;
}

._2TdRSq_mystery-box-card-body-bt {
  text-align: right;
}

._2TdRSq_mystery-box-card-blank {
  color: #07daff;
  font-size: 12px;
  text-decoration: none;
}

._2TdRSq_mystery-box-card-blank:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid #0000;
  border-left-color: #07daff;
  margin-left: 5px;
  display: inline-block;
  position: relative;
}

._2TdRSq_mystery-box-card-blank:hover {
  color: #ffcf30;
}

._2TdRSq_mystery-box-card-blank:hover:after {
  border-color: #0000 #0000 #0000 #ffcf30;
}

._2TdRSq_mystery-box-card-body ._2TdRSq_box {
  width: 100%;
  height: 250px;
  overflow: hidden;
}

._2TdRSq_mystery-box-card-body ._2TdRSq_box ._2TdRSq_lottie {
  bottom: 10px;
}

._2TdRSq_box-op-wrap {
  max-width: 400px;
  font-size: 12px;
}

._2TdRSq_progress-wrap {
  height: 30px;
}

._2TdRSq_progress-bar {
  height: 12px;
  width: calc(100% - 50px);
  background: #050d21;
  border: 1px solid #222f49;
  border-radius: 6px;
}

._2TdRSq_progress-bar-inner {
  height: 8px;
  border-radius: 8px;
}

._2TdRSq_progress-bar-4 {
  background: linear-gradient(#ffcf30 0%, #e49800 100%);
  box-shadow: inset 0 -1px 3px #c26100;
}

._2TdRSq_progress-bar-3 {
  background: linear-gradient(#eff45a 0%, #266524 100%);
  box-shadow: inset 0 -1px 3px #153005;
}

._2TdRSq_progress-bar-badge {
  width: 40px;
  height: 28px;
  color: #fff;
  text-shadow: 0 1px 2px #286613;
  z-index: 3;
  background: linear-gradient(#ff9500 0%, #ffbf00 100%);
  border: 1px solid #ffcd27;
  border-radius: 14px;
  font-family: ADAM\.CG PRO, sans-serif;
  font-size: 1rem;
  line-height: 28px;
  top: 0;
  right: -40px;
  transform: translateY(-10px);
  box-shadow: 0 2px 5px 1px #3f1a00, 0 2px #bf5002;
}

._2TdRSq_progress-bar-3 ._2TdRSq_progress-bar-badge {
  background: linear-gradient(#32a226 0%, #78c848 100%);
  border: 1px solid #c6ff27;
  border-radius: 14px;
  box-shadow: 0 2px #286613;
}

._2TdRSq_quota3 {
  background: url("3@2x.ca5f1d3b.png");
}

._2TdRSq_quota4 {
  background: url("4@2x.af448a52.png");
}

._2TdRSq_quota5 {
  background: url("5@2x.3ba4249f.png");
}

._2TdRSq_quota6 {
  background: url("6@2x.d72a656b.png");
}

._2TdRSq_quota7 {
  background: url("7@2x.266cd30b.png");
}

._2TdRSq_dns {
  background: url("logo.616677dc.png");
}

._2TdRSq_prize {
  width: 28px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: contain;
}

._2TdRSq_btn {
  cursor: pointer;
  width: 100%;
  height: 44px;
  -webkit-tap-highlight-color: transparent;
  border-radius: 12px;
  outline: none;
  margin: 0 auto 1rem;
  font-weight: normal;
  line-height: 44px;
  display: block;
  color: #fff !important;
  background-color: #0000 !important;
  padding: 0 !important;
  font-family: Eras Demi ITC !important;
  font-size: 20px !important;
}

._2TdRSq_btn-buy, ._2TdRSq_btn-confirm {
  background: linear-gradient(#ff9500 0%, #ffbf00 100%);
  box-shadow: 0 6px #bf5002;
  text-shadow: 0 0 4px #fb6a01 !important;
  border: 1px solid #ffcd27 !important;
}

._2TdRSq_btn-buy:hover, ._2TdRSq_btn-confirm:hover {
  background: linear-gradient(#ffb800 0%, #ffda6e 100%) !important;
  box-shadow: 0 6px #ff7c00 !important;
}

._2TdRSq_btn-buy:active, ._2TdRSq_btn-confirm:active {
  background: linear-gradient(#ef7600 0%, #ffbf00 100%) !important;
  box-shadow: 0 6px #bf5002 !important;
}

._2TdRSq_btn-connect {
  text-shadow: 0 0 4px #008054;
  background: linear-gradient(#0c8 0%, #00ff80 100%) !important;
  border: 1px solid #00ff87 !important;
  box-shadow: 0 6px #008054 !important;
}

._2TdRSq_btn-connect:hover {
  background: linear-gradient(#0fa 0%, #73ffb9 100%) !important;
  border: 1px solid #6dffba !important;
  box-shadow: 0 6px #00bd7d !important;
}

._2TdRSq_btn-connect:active {
  background: linear-gradient(#008b61 0%, #00df70 100%);
  border: 1px solid #00ff87;
  box-shadow: 0 6px #008054;
}

._2TdRSq_btn-dicp {
  text-shadow: 0 0 4px #005580;
  background: linear-gradient(#08c 0%, #0af 100%) !important;
  border: 1px solid #0af !important;
  box-shadow: 0 6px #005580 !important;
}

._2TdRSq_btn-dicp:hover {
  background: linear-gradient(#0af 0%, #55c6ff 100%) !important;
  border: 1px solid #52c5ff !important;
  box-shadow: 0 6px #007dbc !important;
}

._2TdRSq_btn-dicp:active {
  background: linear-gradient(#004d94 0%, #0af 100%) !important;
  border: 1px solid #0af !important;
  box-shadow: 0 6px #005580 !important;
}

._2TdRSq_btn-disable {
  cursor: not-allowed !important;
  background: #3e485d !important;
  border: 1px solid #6075a0 !important;
  box-shadow: 0 6px #2e3646 !important;
}

._2TdRSq_box-btn {
  cursor: pointer;
  width: 55px;
  height: 40px;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(#ff9500 0%, #ffbf00 100%);
  border: 1px solid #ffcd27;
  border-radius: 12px;
  outline: none;
  box-shadow: 0 4px #bf5002;
}

._2TdRSq_box-btn:hover {
  background: linear-gradient(#ffb800 0%, #ffda6e 100%);
  box-shadow: 0 4px #ff7c00;
}

._2TdRSq_box-btn:active {
  background: linear-gradient(#ef7600 0%, #ffbf00 100%);
  box-shadow: 0 4px #bf5002;
}

._2TdRSq_box-form {
  height: 50px;
}

._2TdRSq_input-number {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 2px #bf5002;
  background: #09112a;
  border: 1px solid #40598a;
  border-radius: 12px;
  outline: none;
  margin: 0 10px;
  font-family: ADAM\.CG PRO, sans-serif;
  font-size: 20px;
  line-height: 40px;
  box-shadow: 0 3px #1e386c;
}

._2TdRSq_input-number::-webkit-inner-spin-button, ._2TdRSq_input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._2TdRSq_input-number:focus {
  background: #101c40;
  border: 1px solid #84adff;
  box-shadow: 0 3px #204388;
}

._2TdRSq_fixed-wrap {
  z-index: 1100;
  background: linear-gradient(#081135 0%, #050a21 100%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

._2TdRSq_fixed-wrap:before {
  content: "";
  width: 1018px;
  height: 619px;
  opacity: .3;
  filter: blur(50px);
  background: linear-gradient(#00c568 0%, #00c56800 100%);
  display: block;
  position: absolute;
}

._2TdRSq_fixed-wrap:after {
  content: "";
  width: 914px;
  height: 457px;
  opacity: .25;
  filter: blur(50px);
  background: linear-gradient(#0093ff 0%, #0093ff00 100%);
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

._2TdRSq_open-warp {
  height: 100%;
  z-index: 9;
}

._2TdRSq_open-box {
  width: 100%;
  height: 342px;
}

._2TdRSq_open-prize {
  max-width: 800px;
}

._2TdRSq_open-prize-inner {
  height: 70vh;
}

._2TdRSq_prize-row {
  background: url("light@2x.20a56408.png") center / cover no-repeat;
  padding: 0 5rem;
}

._2TdRSq_prize-row-sup {
  height: 35px;
  color: #ffbe00;
  background-image: linear-gradient(#ff9a00 0%, #fac827 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Eras Demi ITC;
  font-size: 30px;
  font-weight: normal;
}

._2TdRSq_prize-row-sub {
  height: 28px;
  color: #ffbe00;
  background-image: linear-gradient(#ff9a00 0%, #fac827 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Eras Demi ITC;
  font-size: 24px;
  font-weight: normal;
}

._2TdRSq_prize-number {
  height: 29px;
  background-image: linear-gradient(#ff9a00 0%, #fac827 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Eras Demi ITC;
  font-size: 24px;
  font-weight: 100;
}

._2TdRSq_animate {
  animation-duration: .5s;
  animation-fill-mode: both;
}

._2TdRSq_animate._2TdRSq_animate__delay-1s {
  animation-delay: .9s;
}

@keyframes _2TdRSq_zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.3, .1, .3);
  }

  50% {
    opacity: 1;
  }
}

._2TdRSq_animate_zoomIn {
  animation-name: _2TdRSq_zoomIn;
}

._2TdRSq_footer {
  background-image: linear-gradient(360deg, #0c1e45 0%, #0d153e 0%, #090e2a 100%);
}

._2TdRSq_footer ._2TdRSq_footer-link {
  color: #1d2863;
  margin: .5rem;
  transition: color .3s;
}

._2TdRSq_footer ._2TdRSq_footer-link:last-child:before {
  content: "";
  width: 1px;
  height: 1.5rem;
  background: #1d2863;
  margin: 0 1rem 0 0;
  display: inline-block;
}

._2TdRSq_footer ._2TdRSq_footer-link:hover, ._2TdRSq_footer ._2TdRSq_footer-link:focus {
  color: #07daff;
}

._2TdRSq_footer ._2TdRSq_footer-link ._2TdRSq_iconfont {
  font-size: 1.9rem;
}

._2TdRSq_footer ._2TdRSq_footer-link ._2TdRSq_icon-twitter, ._2TdRSq_footer ._2TdRSq_footer-link ._2TdRSq_icon-medium {
  font-size: 2.1rem;
}

._2TdRSq_footer ._2TdRSq_footer-link ._2TdRSq_icon-arrow-down {
  font-size: 2rem;
}

@media (min-width: 576px) {
  ._2TdRSq_progress-bar-badge {
    line-height: 23px;
  }

  ._2TdRSq_mystery-box-card-body ._2TdRSq_box {
    width: 365px;
    height: 250px;
  }

  ._2TdRSq_open-box {
    width: 390px;
    height: 100%;
  }

  ._2TdRSq_btn {
    width: 400px;
  }

  ._2TdRSq_open-prize-inner {
    height: 62vh;
  }
}

._2TdRSq_wallet-logo {
  width: 1.5rem;
  margin-right: 10px;
}

._2TdRSq_loading {
  height: 100%;
  z-index: 888;
  background: #00000080;
  border-radius: 6px;
  top: 0%;
  left: 0%;
}

._2TdRSq_spinner {
  width: 3rem;
  height: 3rem;
  border: 5px solid #0000;
  border-top-color: #00cfbb;
  border-radius: 100%;
  animation: 1s linear infinite _2TdRSq_spinnerFour;
}

@keyframes _2TdRSq_spinnerFour {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bFVChq_wrapper .bFVChq_icon {
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.bFVChq_wrapper .bFVChq_children, .bFVChq_wrapper .bFVChq_input {
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.bFVChq_wrapper .bFVChq_input:focus {
  box-shadow: none;
}

.bFVChq_wrapper:focus {
  box-shadow: 0 0 0 .25rem #0d6efd40;
}

.bFVChq_custom-search-control {
  color: #8c9ecc !important;
  font-weight: 400 !important;
}

.bFVChq_custom-search-icon {
  color: #8c9ecc !important;
}

.wZZDTa_no-more {
  color: #8c9ecc;
}

.wZZDTa_load-more {
  color: #8c9ecc;
  border-radius: 6px;
  padding: .5rem .6rem;
  box-shadow: 4px 6px 16px #bcc2d980, -4px -6px 16px #ffffffb3;
}

._0h3wRa_borderless input, ._0h3wRa_borderless button {
  border: none !important;
}

._0h3wRa_borderless input:focus, ._0h3wRa_borderless button:focus {
  box-shadow: none;
}

.N0oIwW_toast-position {
  z-index: 2000;
  position: fixed;
  top: 6rem;
  right: 10vw;
}

@media (max-width: 576px) {
  .N0oIwW_toast-position {
    right: 1.625rem;
  }
}

.N0oIwW_toast-div {
  border-radius: .625rem;
  padding: 1.875rem;
}

.N0oIwW_loading-line {
  width: 0;
  animation-name: N0oIwW_loading-line;
  animation-duration: var(--toast-animation-time);
  border-bottom: 2px solid #2d43ff;
  animation-iteration-count: infinite;
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes N0oIwW_loading-line {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.Qyfi4a_spin {
  border: 3px solid #2d43ff;
  border-bottom-color: #ffffff4d;
  border-radius: 50%;
  animation: 1s linear infinite Qyfi4a_spin;
  display: inline-block;
}

@keyframes Qyfi4a_spin {
  to {
    transform: rotate(360deg);
  }
}

.Qyfi4a_lds-spinner {
  color: official;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  position: relative;
}

.Qyfi4a_lds-spinner div {
  transform-origin: .5rem .5rem;
  animation: 1.2s linear infinite Qyfi4a_lds-spinner;
}

.Qyfi4a_lds-spinner div:after {
  content: " ";
  width: 1px;
  height: 3px;
  background: #2d43ff;
  border-radius: 20%;
  display: block;
  position: absolute;
  top: 1px;
  left: 6px;
}

.Qyfi4a_lds-spinner div:nth-child(1) {
  animation-delay: -1.1s;
  transform: rotate(0);
}

.Qyfi4a_lds-spinner div:nth-child(2) {
  animation-delay: -1s;
  transform: rotate(30deg);
}

.Qyfi4a_lds-spinner div:nth-child(3) {
  animation-delay: -.9s;
  transform: rotate(60deg);
}

.Qyfi4a_lds-spinner div:nth-child(4) {
  animation-delay: -.8s;
  transform: rotate(90deg);
}

.Qyfi4a_lds-spinner div:nth-child(5) {
  animation-delay: -.7s;
  transform: rotate(120deg);
}

.Qyfi4a_lds-spinner div:nth-child(6) {
  animation-delay: -.6s;
  transform: rotate(150deg);
}

.Qyfi4a_lds-spinner div:nth-child(7) {
  animation-delay: -.5s;
  transform: rotate(180deg);
}

.Qyfi4a_lds-spinner div:nth-child(8) {
  animation-delay: -.4s;
  transform: rotate(210deg);
}

.Qyfi4a_lds-spinner div:nth-child(9) {
  animation-delay: -.3s;
  transform: rotate(240deg);
}

.Qyfi4a_lds-spinner div:nth-child(10) {
  animation-delay: -.2s;
  transform: rotate(270deg);
}

.Qyfi4a_lds-spinner div:nth-child(11) {
  animation-delay: -.1s;
  transform: rotate(300deg);
}

.Qyfi4a_lds-spinner div:nth-child(12) {
  animation-delay: 0s;
  transform: rotate(330deg);
}

@keyframes Qyfi4a_lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.KiAv1G_custom-badge {
  border-top-left-radius: 6.25rem;
  border-bottom-right-radius: 6.25rem;
  padding: 2px 30px 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 576px) {
  .KiAv1G_custom-badge {
    padding: 2px 24px 3px;
    font-size: 9px;
    line-height: 14px;
  }
}

.KiAv1G_list-height {
  height: 5.625rem;
}

@media (max-width: 576px) {
  .KiAv1G_list-height {
    min-height: 4.25rem;
  }
}

.KiAv1G_outline-purple {
  color: #7a2dff !important;
  border: 1px solid #7a2dff !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
}

.KiAv1G_outline-purple:hover {
  color: #fff !important;
  background-color: #7a2dff !important;
}

.btn-outline-primary_723f42 {
  color: rgb(var(--bs-primary-rgb)) !important;
  border-color: rgb(var(--bs-primary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-primary-rgb), .1) !important;
}

.btn-outline-secondary_723f42 {
  color: rgb(var(--bs-secondary-rgb)) !important;
  border-color: rgb(var(--bs-secondary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-secondary-rgb), .1) !important;
}

.btn-outline-warning_723f42 {
  color: rgb(var(--bs-warning-rgb)) !important;
  border-color: rgb(var(--bs-warning-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-warning-rgb), .1) !important;
}

.fs-10_723f42 {
  font-size: 10px !important;
}

.fs-12_723f42 {
  font-size: 12px !important;
}

.fs-14_723f42 {
  font-size: 14px !important;
}

.fs-16_723f42 {
  font-size: 16px !important;
}

.fs-18_723f42 {
  font-size: 18px !important;
}

.fs-20_723f42 {
  font-size: 20px !important;
}

.migration_723f42 {
  font-size: 14px;
}

.migration_723f42 .btn-link {
  font-size: 14px;
  display: inline;
  text-decoration: none !important;
}

@media (min-width: 576px) {
  .migration_723f42, .migration_723f42 .btn-link {
    font-size: 16px;
  }
}

.mySale_723f42 {
  color: #8c9ecc;
  font-size: 14px;
  position: absolute;
  top: 24px;
  right: 0;
}

.mySale_723f42 .form-check {
  margin-top: 5px;
}

.custom-pillnav_723f42 {
  height: 44px;
  flex-wrap: nowrap !important;
  padding: 7px .7rem 0 !important;
}

@media (min-width: 576px) {
  .custom-pillnav_723f42 {
    padding: 7px 1rem 0 !important;
  }
}

.custom-pillnav_723f42 .nav-link {
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  color: #58627a80 !important;
  padding: 0 1.1rem !important;
}

.custom-pillnav_723f42 .nav-link.active {
  font-weight: 500;
  box-shadow: inset -.5rem -.75rem 1rem #ffffffb3, inset .5rem .75rem 1rem #bcc2d980;
  color: #0d1220 !important;
  background-color: #ffffffb3 !important;
  border-radius: 6px !important;
}

.nav-dark_1b379f {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: #0f173d;
}

.nav-light_1b379f {
  background: var(--bs-body-bg);
}

.logo_1b379f {
  height: 2rem;
}

.darkOffCanvas_1b379f {
  background: #0f173d;
}

.darkOffCanvas_1b379f .btnConnect_1b379f {
  border-radius: 6px;
  color: #00cfbb !important;
  background: #00cfbb1a !important;
  border: 1px solid #00cfbb !important;
}

.darkOffCanvas_1b379f .dropdown-menu {
  background: #0f173d;
}

.darkOffCanvas_1b379f .text-secondary, .darkOffCanvas_1b379f .dropdown-item {
  color: #fff !important;
}

.darkOffCanvas_1b379f .navbar-collapse .nav-link.active, .darkOffCanvas_1b379f .dropdown-item:hover {
  color: #00cfbb !important;
  border-color: #00cfbb !important;
}

.avatar_1b379f {
  width: 50px;
  margin-left: 1rem;
}

@media (max-width: 576px) {
  .avatar_1b379f {
    margin-bottom: 1.25rem;
    margin-left: 0;
  }
}

.divider_1b379f {
  border-color: #4b4b631a !important;
}

.OBDVjW_title {
  width: -webkit-max-content;
  width: max-content;
  color: #121315;
  background-image: linear-gradient(90deg, #16d275 0, #0685ff 50.2791%, #50f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
}

.-\38 D4Ka_range {
  width: 4rem !important;
}

.G0PYmG_btn-outline-primary {
  color: rgb(var(--bs-primary-rgb)) !important;
  border-color: rgb(var(--bs-primary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-primary-rgb), .1) !important;
}

.G0PYmG_btn-outline-secondary {
  color: rgb(var(--bs-secondary-rgb)) !important;
  border-color: rgb(var(--bs-secondary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-secondary-rgb), .1) !important;
}

.G0PYmG_btn-outline-warning {
  color: rgb(var(--bs-warning-rgb)) !important;
  border-color: rgb(var(--bs-warning-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-warning-rgb), .1) !important;
}

.G0PYmG_fs-10 {
  font-size: 10px !important;
}

.G0PYmG_fs-12 {
  font-size: 12px !important;
}

.G0PYmG_fs-14 {
  font-size: 14px !important;
}

.G0PYmG_fs-16 {
  font-size: 16px !important;
}

.G0PYmG_fs-18 {
  font-size: 18px !important;
}

.G0PYmG_fs-20 {
  font-size: 20px !important;
}

.G0PYmG_accordion-body-custom {
  background: #fff;
  border-radius: 6px;
  padding: 1.875rem;
  overflow: hidden;
  box-shadow: inset -.5rem -.75rem 1rem #ffffffb3, inset .5rem .75rem 1rem #bcc2d980;
}

.G0PYmG_filter-custom-desktop {
  background-color: #fff;
  border-radius: 20px;
  margin-top: 1rem;
  padding: 1.875rem;
  box-shadow: .5rem .75rem 1rem #bcc2d980, -.5rem -.75rem 1rem #ffffffb3;
}

.G0PYmG_filter-custom-phone {
  background: #fff;
  margin-top: 1rem;
  padding: 1.875rem;
}

.G0PYmG_form-select {
  color: #2d43ff !important;
}

.G0PYmG_form-check .G0PYmG_form-check-input {
  margin-top: 0;
  margin-left: 0;
}

.G0PYmG_form-check-input {
  border: 1px solid #8c9ecc;
  border-radius: 5px;
  width: 20px !important;
  height: 20px !important;
}

.G0PYmG_form-check-input:checked {
  background-color: #8c9ecc !important;
  border-color: #8c9ecc !important;
}

.D-Nkmq_btn-outline-primary {
  color: rgb(var(--bs-primary-rgb)) !important;
  border-color: rgb(var(--bs-primary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-primary-rgb), .1) !important;
}

.D-Nkmq_btn-outline-secondary {
  color: rgb(var(--bs-secondary-rgb)) !important;
  border-color: rgb(var(--bs-secondary-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-secondary-rgb), .1) !important;
}

.D-Nkmq_btn-outline-warning {
  color: rgb(var(--bs-warning-rgb)) !important;
  border-color: rgb(var(--bs-warning-rgb)) !important;
  --bs-btn-active-bg: white !important;
  --bs-btn-hover-bg: rgba(var(--bs-warning-rgb), .1) !important;
}

.D-Nkmq_fs-10 {
  font-size: 10px !important;
}

.D-Nkmq_fs-12 {
  font-size: 12px !important;
}

.D-Nkmq_fs-14 {
  font-size: 14px !important;
}

.D-Nkmq_fs-16 {
  font-size: 16px !important;
}

.D-Nkmq_fs-18 {
  font-size: 18px !important;
}

.D-Nkmq_fs-20 {
  font-size: 20px !important;
}

.D-Nkmq_title {
  width: -webkit-max-content;
  width: max-content;
  color: #121315;
  background-image: linear-gradient(90deg, #16d275 0, #0685ff 50.2791%, #50f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Montserrat;
}

.D-Nkmq_custom-img {
  max-width: 50%;
}

.D-Nkmq_card:hover {
  cursor: pointer;
  background-color: #fff;
  border-radius: 1rem;
  padding: 0;
  overflow: hidden;
  box-shadow: .5rem .75rem 1rem #bcc2d980, -.5rem -.75rem 1rem #ffffffb3;
}

.D-Nkmq_card:hover .D-Nkmq_download {
  background-color: #2d43ff1a;
}

/*# sourceMappingURL=index.aa1c2c00.css.map */
