:root {
  --bs-body-bg: #eff1f6 !important;
  --bs-body-color: rgba(88, 98, 122, 1);
  --bs-body-color-rgb: 88, 98, 122 !important;
  --bs-primary-rgb: 45, 67, 255 !important;
  --bs-secondary-rgb: 88, 98, 122 !important;
  --bs-info-rgb: 140, 158, 204 !important;
  --bs-warning-rgb: 255, 143, 45 !important;
  --bs-success-rgb: 14, 164, 11 !important;
}

/**
 * Bootstrap classes
 */
.btn-secondary {
  background-color: rgb(var(--bs-secondary-rgb)) !important;
  border-color: rgb(var(--bs-secondary-rgb)) !important;
}

@colors: primary, secondary, warning;

each(@colors, {
    .btn-outline-@{value} {
      color: rgb(var(~"--bs-@{value}-rgb")) !important;
      border-color: rgb(var(~"--bs-@{value}-rgb")) !important;
      --bs-btn-active-bg: white !important;
      --bs-btn-hover-bg:rgba(var(~"--bs-@{value}-rgb"), 0.1) !important;
    }
  }

);

.form-label {
  font-weight: 500;
}

.form-text {
  display: block;
  margin-top: 0.5rem !important;
}

.form-control {
  color: var(--bs-info-rgb) !important;
}

.modal-content {
  .round-card();
  box-shadow: none;
  background: #eff1f6 !important;
}

/**
 * Custom classes
 */
each(range(6), {
    @size: 8 + @value * 2;

    .fs-@{size} {
      font-size: unit(@size, px) !important;
    }
  }

);

.fw-500 {
  font-weight: 500 !important;
}

.inner-shadow {
  box-shadow: inset -0.5rem -0.75rem 1rem 0 rgba(255, 255, 255, 0.7),
    inset 0.5rem 0.75rem 1rem 0 rgba(188, 194, 217, 0.5);
}

.outer-shadow {
  box-shadow: 0.5rem 0.75rem 1rem 0 rgba(188, 194, 217, 0.5),
    -0.5rem -0.75rem 1rem 0 rgba(255, 255, 255, 0.7);
  background-color: white;
}

.appearance-none {
  appearance: none;
  font-size: inherit;
  color: inherit;
  background: none;
  border: none;
}

:not(:empty) {
  &::-webkit-scrollbar {
    width: 2px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(59, 73, 102);
    border-radius: 1px;
  }
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

.round-card {
  .outer-shadow();
  border-radius: 2.5rem !important;

  .form-control:focus {
    background: rgba(45, 67, 255, 0.08);
    border: 1px solid #2d43ff;
    box-shadow: none;
  }

  &,
  td {
    padding: 1.5rem !important;
  }

  tr {
    &:first-child > td {
      &:first-child {
        border-top-left-radius: 2.5rem;
      }

      &:last-child {
        border-top-right-radius: 2.5rem;
      }
    }

    &:last-child > td {
      &:first-child {
        border-bottom-left-radius: 2.5rem;
      }

      &:last-child {
        border-bottom-right-radius: 2.5rem;
      }
    }
  }
}

.modal-dialog {
  .modal-header {
    border: none;
  }

  .modal-body {
    padding-top: 0;
  }
}

.table1 {
  thead {
    th {
      border: none !important;
    }
  }

  tbody {
    .round-card();
    border: none !important;

    td {
      border: none !important;
      text-align: left;
    }
  }
}

.accordion {
  --bs-accordion-btn-padding-x: 0rem !important;
}

.accordion-button {
  color: rgba(13, 18, 32, 1) !important;
  font-weight: 500;
  --bs-accordion-active-color: rgb(255, 255, 255) !important;
  --bs-accordion-active-bg: rgb(255, 255, 255) !important;
  --bs-accordion-btn-focus-border-color: rgba(255, 255, 255, 1) !important;
  --bs-accordion-btn-focus-box-shadow: none !important;

  &:not(.collapsed)::after {
    background-image: url('../image/arrow-down.svg') !important;
  }

  &::after {
    background-image: url('../image/arrow-down.svg') !important;
  }
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(45, 67, 255, 1) !important;
  --bs-btn-border-color: rgba(45, 67, 255, 1) !important;
}

.navbar-collapse {
  .nav-link {
    &.active {
      border-bottom: 2px solid rgba(45, 67, 255, 1) !important;
      color: rgba(45, 67, 255, 1) !important;

      @media (max-width: 576px) {
        border-bottom: none !important;
      }
    }
  }
}

.offcanvas.offcanvas-end {
  width: 60% !important;
}

.nav-pills {
  font-weight: 500 !important;

  .nav-link {
    color: black !important;

    &.active {
      color: white !important;

      background-color: rgb(var(--bs-primary-rgb)) !important;
    }
  }
}

.navbar-text {
  .dropdown-item:hover {
    background-color: rgba(45, 67, 255, 0.05) !important;
    color: rgb(var(--bs-primary-rgb)) !important;
  }
}

//To remove search register page input type="number" arrows
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input[type='search']::-webkit-search-cancel-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.btn-orange,
.btn-transfer,
.btn-custom-blue {
  border-color: transparent !important;
  padding: 6px 10px !important;
}
.btn-orange {
  color: #ff8f2d !important;
}
.btn-transfer {
  color: #7a2dff !important;
}

.custom-list-wrap {
  margin-bottom: 4rem !important;
  padding-bottom: 4rem !important;
}
